import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import ScrollToTop from './ScrollToTop';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
  }
  ::before,
  ::after {
    box-sizing: inherit;
  }

  html {
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
  }

  body {
    width: 100vw;
    height: 100vh;
    font-size: 100%;
    background: #fafafa;
  }

  h1, h2, h3 {
    font-family: 'Raleway', sans-serif;
  }

  button:focus {
    outline: 3px dotted #560027 !important;
  }

  a:focus {
    outline: 3px dotted #560027 !important;
  }

  @media screen and (min-width: 450px) {
    html {
      font-size: 112.5%;
    }
  }

  @media all and (min-width: 1200px) {
    html {
      font-size: 125%;
      line-height: 1.8;
    }
  }
`;

const theme = {
  primary: {
    main: '#fafafa',
    light: '#fff',
    dark: '#c7c7c7',
    contrastText: '#000'
  },
  secondary: {
    main: '#880e4f',
    light: '#bc477b',
    dark: '#560027',
    contrastText: '#fff'
  }
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
    <GlobalStyle />
  </ThemeProvider>,
  document.getElementById('root')
);
registerServiceWorker();
