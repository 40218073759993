import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100vw;
  padding: 2em;
  background: ${props => props.theme.primary.light};
  z-index: 2;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  @media screen and (min-width: 780px) {
    flex-flow: row nowrap;
    justify-self: flex-end;
  }
`;

const NavBranding = styled.div`
  order: 1;
`;

const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  order: 3;
  width: 100vw;
  @media screen and (min-width: 780px) {
    width: 50%;
    order: 2;
  }
`;

const Languages = styled.div`
  order: 2;
  @media screen and (min-width: 780px) {
    order: 3;
  }
`;

const Button = styled.button`
  font-size: 1em;
  padding: 0.25em;
  margin-left: 0.5em;
  font-weight: 700;
  color: ${props =>
    props.activeLang === props.value
      ? props.theme.secondary.dark
      : props.theme.primary.dark};
  background: ${props => props.theme.primary.light};
  &:hover {
    color: ${props => props.theme.secondary.dark};
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${props => props.theme.primary.contrastText};
  opacity: 0.5;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.secondary.dark};
    font-weight: 700;
    opacity: 1;
  }
  &.active {
    opacity: 1;
    font-weight: 700;
    color: ${props => props.theme.secondary.dark};
    border-bottom: 0.2em solid ${props => props.theme.secondary.dark};
  }
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.secondary.dark};
  font-weight: 700;
  opacity: 1;
  text-decoration: none;
`;

const Header = props => {
  const { changeLanguage, activeLang } = props;

  return (
    <Container>
      <NavBranding>
        <StyledLink exact="true" to="/">
          Fabiola Vieyra
        </StyledLink>
      </NavBranding>
      <Nav id="navMenu">
        <StyledNavLink exact to="/">
          {activeLang === 'en' ? 'Projects' : 'Proyectos'}
        </StyledNavLink>
        <StyledNavLink to="/resume">
          {activeLang === 'en' ? 'Résumé' : 'CV'}
        </StyledNavLink>
        <StyledNavLink to="/contact">
          {activeLang === 'en' ? 'Contact' : 'Contacto'}
        </StyledNavLink>
      </Nav>
      <Languages>
        <Button
          name="select-english"
          value="en"
          activeLang={activeLang}
          onClick={changeLanguage}
        >
          en
        </Button>
        <Button
          name="select-spanish"
          value="es"
          activeLang={activeLang}
          onClick={changeLanguage}
        >
          es
        </Button>
      </Languages>
    </Container>
  );
};

export default Header;
