import React, { Fragment } from 'react';
import styled from 'styled-components';

import flad from '../images/flad.png';
import trueRelief from '../images/true-relief.png';
import myWebsite from '../images/home-page.png';
import lindaWeb from '../images/linda-website.png';
import map from '../images/nanodegree-projects/neighborhood-map.png';
import arcadeGame from '../images/nanodegree-projects/arcade-game.png';
import memoryGame from '../images/nanodegree-projects/memory-game.png';
import portfolio from '../images/nanodegree-projects/portfolio.png';
import simonGame from '../images/free-code-camp/simon-game.png';
import ticTacToe from '../images/free-code-camp/tic-tac-toe.png';
import pomodoro from '../images/free-code-camp/pomodoro-clock.png';
import calculator from '../images/free-code-camp/react-calculator.png';
import twitchTv from '../images/free-code-camp/twitchtv_app.png';
import filmDatabase from '../images/free-code-camp/film-database.png';

const Container = styled.section`
  width: 100vw;
  background: ${props => props.theme.secondary.main};
  padding-top: 112px;
  @media screen and (min-width: 768px) {
    padding-top: 256px;
  }
`;

const Wrapper = styled.section`
  margin: 2em;
  @media screen and (min-width: 768px) {
    width: 60%;
    display: flex;
    flex-flow: column wrap;
    margin: 0 auto;
  }
`;

const Title = styled.h3`
  font-size: 2em;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.primary.light};
`;

const Subheading = styled.p`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.primary.light};
`;

const ProjectTitle = styled.div`
  padding: 100px 0 200px 0;
`;

const Article = styled.article`
  margin: 3em 0;
  @media screen and (min-width: 768px) {
    margin: 6em 0 10em 0;
  }
`;

const ImageSection = styled.figure`
  margin: 1.5em 0;
  padding: 3em 2em;
  display: flex;
  justify-content: center;
  background: ${props => props.theme.primary.dark};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  @media screen and (min-width: 768px) {
    width: 60%;
    height: 60%;
  }
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
  margin: 2em 0;
`;

const ButtonLink = styled.a`
  padding: 0.25em 1em;
  margin: 1.5em 1em 0 0;
  border-radius: 1em;
  color: ${props => props.theme.secondary.light};
  border: 0.1em solid ${props => props.theme.secondary.light};
  background: ${props => props.theme.primary.light};
  transition: all 0.2s ease;
  text-decoration: none;
  line-height: 4;
  &:hover {
    color: ${props => props.theme.primary.light};
    border: 0.1em solid ${props => props.theme.secondary.light};
    background: ${props => props.theme.secondary.light};
    transform: scale(0.9);
  }
  &:focus {
    outline: auto 5px;
  }
`;

const images = [
  { name: flad, id: 'flad' },
  { name: trueRelief, id: 'trueRelief' },
  { name: myWebsite, id: 'myWebsite' },
  { name: lindaWeb, id: 'lindaWeb' },
  { name: map, id: 'map' },
  { name: arcadeGame, id: 'arcadeGame' },
  { name: memoryGame, id: 'memoryGame' },
  { name: portfolio, id: 'portfolio' },
  { name: simonGame, id: 'simonGame' },
  { name: ticTacToe, id: 'ticTacToe' },
  { name: pomodoro, id: 'pomodoro' },
  { name: calculator, id: 'calculator' },
  { name: twitchTv, id: 'twitchTv' },
  { name: filmDatabase, id: 'filmDatabase' }
];

const ProjectDetail = props => {
  const { data, match } = props;
  const project = data.projectInfo.find(project => {
    return project.image === match.params.id;
  });

  const projectImg = images.find(image => {
    return image.id === match.params.id;
  });

  return (
    <Fragment>
      <Container>
        <ProjectTitle>
          <Title>{project.title}</Title>
          <Subheading>{project.technologies}</Subheading>
        </ProjectTitle>
      </Container>
      <Wrapper>
        <Article>
          <p>{project.dates}</p>
          <p>{project.technologies}</p>
          <ButtonLink href={project.demo}>Demo</ButtonLink>
          <ButtonLink href={project.repository}>Repository</ButtonLink>
          <Divider />
          <p>{project.description}</p>
        </Article>
      </Wrapper>
      <ImageSection>
        <Image src={`${projectImg.name}`} alt={`${projectImg.name}`} />
      </ImageSection>
    </Fragment>
  );
};

export default ProjectDetail;
