import React, { Fragment } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 2em;
  padding-top: 112px;
  @media screen and (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
    padding-top: 256px;
  }
`;

const Section = styled.section`
  margin-bottom: 2em;
  @media screen and (min-width: 768px) {
    margin-bottom: 4em;
  }
`;

const Container = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
`;

const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 1.5em;
`;

const Title = styled.h2`
  font-size: 2em;
  @media screen and (min-width: 768px) {
    font-size: 2.5em;
  }
`;

const Subtitle = styled.h3`
  font-size: 1.2em;
  color: ${props => props.theme.primary.dark};
  margin: 0 0.5em 0.5em 0;
  @media screen and (min-width: 768px) {
    font-size: 1.5em;
  }
`;

const ColumnMedium = styled.div`
  @media screen and (min-width: 768px) {
    width: 70%;
  }
`;

const Article = styled.article`
  margin-bottom: 1em;
`;

const Link = styled.a`
  color: ${props => props.theme.secondary.light};
  font-weight: 700;
  opacity: 1;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.secondary.dark};
    font-weight: 700;
  }
`;

const Profile = props => {
  const skills = props.data.skills;
  const languages = props.data.languages;
  const activeLang = props.activeLang;

  return (
    <Fragment>
      <Section>
        <SectionTitle>
          <Title>{props.data.name}</Title>
          <Subtitle>{props.data.role}</Subtitle>
        </SectionTitle>
        <p>{props.data.welcomeMsg}</p>
      </Section>
      <Section>
        <Container>
          <div>
            <Subtitle>
              {activeLang === 'en' ? 'Skills' : 'Habilidades'}
            </Subtitle>
          </div>
          <ColumnMedium>
            {Object.keys(skills).map(key => (
              <p key={key}>
                <strong>
                  {key}
                  {': '}
                </strong>
                {skills[key]}
              </p>
            ))}
          </ColumnMedium>
        </Container>
        <Container>
          <div>
            <Subtitle>{activeLang === 'en' ? 'Languages' : 'Idiomas'}</Subtitle>
          </div>
          <ColumnMedium>
            {Object.keys(languages).map(key => (
              <p key={key}>
                <strong>
                  {key}
                  {': '}
                </strong>
                {languages[key]}
              </p>
            ))}
          </ColumnMedium>
        </Container>
      </Section>
    </Fragment>
  );
};

const Job = props => {
  const { position } = props.data;

  return (
    <Fragment>
      <Section>
        <Container>
          <div>
            <Subtitle>{props.data.sectionTitle}</Subtitle>
          </div>
          <ColumnMedium>
            {position.map(position => (
              <Article key={Math.random().toString(36).substr(8, 3)}>
                <h3>{position.employer}</h3>
                <p>{position.workTitle}</p>
                <p>{position.dates}</p>
                <p>{position.workLocation}</p>
                <p>{position.workDescription}</p>
              </Article>
            ))}
          </ColumnMedium>
        </Container>
      </Section>
    </Fragment>
  );
};

const Education = props => {
  const { schools } = props.data;

  return (
    <Section>
      <Container>
        <div>
          <Subtitle>{props.data.sectionTitle}</Subtitle>
        </div>
        <ColumnMedium>
          {schools.map(school => (
            <Article key={Math.random().toString(36).substr(8, 3)}>
              <h3>{school.school}</h3>
              <p>{school.degree}</p>
              <p>{school.dates}</p>
              <p>{school.location}</p>
            </Article>
          ))}
        </ColumnMedium>
      </Container>
    </Section>
  );
};

const Workshops = props => {
  const { data } = props.data
  return (
    <Section>
      <Container>
        <div>
          <Subtitle>{props.data.sectionTitle}</Subtitle>
        </div>
        <ColumnMedium>
          {data.map(ws => (
            <Article key={Math.random().toString(36).substr(8, 3)}>
              <h3>{ws.title}</h3>
              {ws.dates.map(date => (
                <p key={Math.random().toString(36).substr(8, 3)}>{date}</p>
              ))}
            </Article>
          ))}
        </ColumnMedium>
      </Container>
    </Section>
  )
}

const Resume = props => {
  const { jobs, education, bio, workshops } = props.data;
  return (
    <Wrapper>
      <Profile data={bio} activeLang={props.activeLang} />
      <Education data={education} />
      <Job data={jobs} />
      <Workshops data={workshops} />
    </Wrapper>
  );
};

export default Resume;
