const es = {
  bio: {
    name: 'Fabiola Vieyra',
    role: 'Front-End Web Developer',
    bioPic: './images/fab.jpg',
    welcomeMsg:
      'Soy una desarrolladora web autodidacta, apasionada de JavaScript. Actualmente vivo en Madrid, España. Me interesa crear aplicaciones accesibles para todos. Estoy en busca de projectos interesantes y divertidos. Escríbieme si tienes un projecto en el que pueda contribuir o tan solo para saludarme.',
    skills: {
      JavaScript: 'JavaScript, Node.js',
      HTML5: 'Canvas',
      CSS3: 'Flexbox, Grid, Sass',
      Git: 'GitHub',
      'JS Frameworks': 'React'
    },
    languages: {
      Inglés: 'Advance',
      Español: 'Nativo'
    },
    contact: {
      email: 'fabiolacvieyra@gmail.com',
      github: 'https://github.com/Fa-v',
      twitter: '@_Fa_Vi',
      twUrl: 'https://twitter.com/_Fa_Vi',
      location: 'Madrid, España',
      connect: 'Contáctame'
    }
  },
  jobs: {
    position: [
      {
        employer: 'Triporate',
        workTitle: 'Desarrolladora full stack',
        dates: 'Noviembre 2019 - Actualmente',
        workLocation: 'Madrid',
        workDescription:
          'Plataforma para gestionar viajes de negocios. Parte de mi trabajo es integrar APIs de terceros tanto en el front como en el back así como desarrollar las propias.',
        technologies: 'React - Redux, Node.js, MongoDB, Jest, e2e tests'
      },
      {
        employer: 'DIGIBIS',
        workTitle: 'Desarrolladora front end',
        dates: 'Febrero 2019 - Noviembre de 2019',
        workLocation: 'Madrid',
        workDescription:
          'Server-side Java web apps de arquitectura onion. Mantener, refactorizar y desarrollar nuevas funcionalidades para los tres principales productos referentes a librerías, archivos y centros de documentación digitales.',
        technologies: 'Technologías: Compatibilidad entre navegadores, Sass, CSS, Accessibility'
      },
      {
        employer: 'FLAD Mentorship',
        workTitle: 'Desarrolladora front end',
        dates: '2019 - 2019',
        workLocation: 'Madrid',
        workDescription:
          'Aplicación de una página responsiva (SPA). Ayuda a conectar mentores con emprendedores que buscan asesoría dentro de una comunidad de expatriados portugueses en Estados Unidos.',
        technologies: 'React, Material UI y Firebase'
      },
    ],
    sectionTitle: 'Experiencia laboral'
  },
  education: {
    schools: [
      {
        school: 'Fictizia',
        degree: 'React - Redux',
        dates: 'Actualente',
        location: 'Madrid'
      },
      {
        school: 'Fictizia',
        degree: 'Master en programación fullstack con JavaScript y Node.js',
        dates: '2018 - 2019',
        location: 'Madrid'
      },
      {
        title: 'Nanodegree Desarrollo web front-end',
        school: 'Beca de Udacity and Women Techmaker',
        dates: 'September 2017 - February 2018',
        url:
          'https://eu.udacity.com/course/front-end-web-developer-nanodegree--nd001'
      },
      {
        title: 'Desarrollo web front-end',
        school: 'Free Code Camp',
        dates: '2016 - 2017',
        url: 'https://www.freecodecamp.org/fa-v'
      },
    ],
    sectionTitle: 'Educación',
  },
  workshops: {
    data: [
    {
      title: 'Introducción a Git',
      dates: ['Diciembre 2018 – Oficinas de Liferay.']
    },
    {
      title: 'Introducción a JavaScript',
      dates: ['Enero 2019 – Oficinas de Liferay.', 'Marzo 2019 – Nodegirls, Google for Starups']
    },
    {
      title: 'Introducción a Node.js',
      dates: ['Junio 2019 – Nodegirls, Google for Starups']
    },
  ],
  sectionTitle: 'Talleres',
},
  projects: {
    projectInfo: [
      {
        title: 'True Relief',
        dates: 'Actualmente',
        technologies: 'React, Material UI, Firebase',
        description:
          'Single-page, responsive web application construida con React, Material UI y Firebase que permite al usuario crear un registro de cuadros de dolor para poderse comunicar mejor son su médico y recibir sugerencias sobre tratamientos.',
        image: 'trueRelief',
        demo: '',
        repository: 'Escríbime si deseas saber mas de este proyecto.'
      },
      {
        title: 'My página web',
        dates: 'Agosto 2018',
        technologies: 'React, styled components y Netlify',
        description:
          'La primera vez construí mi página web febrero de 2017 con Harp, Bulma para los estilos y GitHub pages para alojarla. Este años decidí actualizarla usando React, styled-components y Netrlify. Mi objetivo principal fue mejorar el estilo de mi página y presentar el contenido tanto en inglés como en español. Durante este proceso he aprendido sobre React Router v4, React Helmet y styled-components.',
        image: 'myWebsite',
        demo: 'https://fabiolavieyra.com',
        repository: ''
      },
      {
        title: 'Portafolio para actriz',
        dates: '2018',
        technologies: 'React, Bulma, Netlify',
        description:
          'Construí este sitio como portafolio para una actriz, en 2017 usé Harp, Materialize para los estilos y Surge para alojarla. Posteriormente la actualicé usando React, Bulma y Netlify.',
        image: 'lindaWeb',
        demo: 'http://www.lindadelgado.com/',
        repository: 'https://github.com/Fa-v/linda-delgado'
      },
      {
        title: 'Neighborhood Map: Bodegas de Vinos en España',
        dates: 'Febrero 2018',
        description:
          'El objetivo de este proyecto es aprender sobre patrones de diseño y usar un framework, es este caso knockout.js, y API externos (Google maps y Foursquare).',
        image: 'map',
        url: 'https://fa-v.github.io/neighborhood-map/'
      },
      {
        title: 'Arcade Game',
        dates: 'Diciembre 2017',
        description:
          'El objetivo de este proyecto es aprender sobre programación orientada a objetos creando un juego usando HTML Canvas',
        image: 'arcadeGame',
        url: 'https://fa-v.github.io/arcade-game/'
      },
      {
        title: 'Memory Game',
        dates: 'Octibre 2017 - Noviembre 2017',
        description:
          'El objetivo de este proyecto es aprender Jquery y manipulación del DOM. Los estilos están hechos con Sass y usé gulp para compilarlo a CSS y para seguir los cambios en todos los archivos.',
        image: 'memoryGame',
        url: 'https://fa-v.github.io/memory-game/'
      },
      {
        title: 'Portfolio',
        dates: 'Octubre 2017',
        description:
          'Portfolio para mostrar todos los los proyectos de Udacity. Usé Tachyons.io para los estilos de este proyecto.',
        image: 'portfolio',
        url: 'https://fa-v.github.io/portfolio/'
      },
      {
        title: 'Simon Game',
        dates: 'Julio 2017 - Agosto 2017',
        description:
          'Este es el último proyecto para Free Code Camp. Lo hice usando React y Create React App. También usé CSS Grid Layout por primera vez y me sorprendió lo fácil que es empezar a implementarlo.',
        image: 'simonGame',
        url: 'https://fa-v.github.io/simon-game/'
      },
      {
        title: 'Tic-tac-toe',
        dates: 'July 2017',
        technologies: 'React, Github Pages',
        description:
          'Tic-tac-toe es el tercer proyecto de la sección avanzada de desarrollo front-end de los projectos de Free Code Camp. Decidí usar React para desarrollar este proyecto. Hay referencias del juego de Tic-tac-toe desde el antiguo Egipto – ¡no sabía esto! Solía jugar cuando era niña usando una hoja de papel o dibujando la cuadrícula con mi dedo en las ventanas durante los días de lluvia. Es un juego muy simple donde el que logre completar una línea vertical, horizontal o diagonal primero es el ganador.',
        image: 'ticTacToe',
        demo: 'https://fa-v.github.io/react-tic-tac-toe/',
        repository: 'https://github.com/Fa-v/react-tic-tac-toe'
      },
      {
        title: 'Pomodoro Clock',
        dates: '2017',
        technologies: 'React, Github Pages',
        description:
          "El segundo proyecto de la sección avanzada de desarrollo front-end de los projectos de Free Code Camp es el 'Pomodoro Clock'. Usé React para construir este proyecto. La técnica del pomodoro es una técnica para manejo del tiempo que te ayuda para concentrarte y trabajar en pequeños periódos de tiempo, tradicionalmente de 25 minutos seguidos de 5 minutos de descanso. I decided to use React to build this project.",
        image: 'pomodoro',
        demo: 'https://fa-v.github.io/react-calculator/',
        repository: 'https://github.com/Fa-v/react-calculator'
      },
      {
        title: 'React Calculator',
        dates: 'June 2017',
        technologies: 'React, Github Pages',
        description:
          'El primer proyecto de la sección avanzada de desarrollo front-end de los projectos de Free Code Camp es una calculadora. Empecé a aprender React trabajando en este proyecto. Al principio React me pareció bastante accesible porque trabajas directamente con JavaScript The Calculator is the first of the advance front-end development projects for Free Code Camp. I decided to use React to build this project. I started to learn React, al ser una librería no tiene tantas capas de abstracción. Usé Creat React App para inicializar mi calculadora y la publiqué en gh-pages.',
        image: 'calculator',
        demo: 'https://fa-v.github.io/react-calculator/',
        repository: 'https://github.com/Fa-v/react-calculator'
      },
      {
        title: 'Twitchtv app',
        dates: 'March 2017',
        technologies: 'Angular2, Materialize, Github Pages',
        description:
          "La applicación de Twitchtv es el último de los proyectos de nivel intermedio de desarrollo front-end de Free Code Camp. Este fue un proyecto desafiante para mí. Primero, de fue complicado entender como usar el Api de Twitchtv. Después de decifrar como funcionaba, tuve que hacer tres peticiones a diferentes endpoints para obtener todos los datos que iba a necesitar. Decidí usar Angular 2 y Materializa para desarrollar este proyecto. Aprendí sobre los observables en este momento porque estaba buscando la mejor manera de procesar la respuesta de las tres peticiones al Api de Twitchtv. Descubrí que con los observables puedes hacer peticiones simultáneas y procesar los datos en el stream que los observables crean. Aprendí que con el método 'forkJoin' puedes hacer peticiones en paralelo y construir un array combinando los datos de todas las peticiones. Para publicar la aplicación en las páginas de GitHub ejecuté en la terminal el comando 'ng build --prod' el cual crea una carpeta dist con el código comprimido para usarlo en producción. Después ejecuté 'ng github-pages:deploy' en la terminal para hacer el deploy de la aplicación en GitHub y que fuese pública.",
        image: 'twitchTv',
        demo: 'https://fa-v.github.io/twitchtv_app/',
        repository: 'https://github.com/Fa-v/twitchtv_app'
      },
      {
        title: 'Film Database',
        dates: 'November 2016',
        technologies: 'JQuery, HTML, CSS',
        description:
          'Este fue uno de los proyectos del curso de desarrollo con MEAN stack. Tuvimos aproximadamente una semana para trabajar en él. El objetivo era practicar un CRUD básico localmente con el que podíamos agregar, modificar, borrar y actualizar el título de una película. El siguiente ejercicio fue conectar la interface con Json server para practicar peticiones ajax. Por último construimos el MEAN stack usando Angular 2 y MongoDB, en este caso yo usé MongoLab y Express para el servidor.',
        image: 'filmDatabase',
        demo: 'https://codepen.io/Fa-v/pen/VPeKEM',
        repository: 'https://github.com/Fa-v/cine-angular2'
      }
    ],
    sectionTitle: 'Proyectos'
  },
  places: 'Donde he vivido y trabajado',
  display: {
    bio: 'displayBio',
    jobs: 'displayJobs',
    projects: 'displayProjects',
    education: 'displayEducation',
    places: 'displayPlaces'
  }
};

export default es;
