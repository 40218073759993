import React, { Fragment } from 'react';
import styled from 'styled-components';
import { TwitterIcon, GithubIcon, EnvelopeIcon } from './Icons';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100vw;
  padding: 2em;
  margin-top: 3em;
  background: ${props => props.theme.primary.main};
`;

const Banner = styled.div`
  background: ${props => props.theme.primary.dark};
  font-size: 0.6em;
  padding: 2em;
  text-align: center;
  a {
    color: ${props => props.theme.secondary.light};
    text-decoration: none;
  }
`;

const Footer = props => {
  return (
    <Fragment>
      <Container>
        <a title="Email" href="mailto:fabiolacvieyra@gmail.com">
          <EnvelopeIcon />
        </a>
        <a title="GitHub" href={props.data.github}>
          <GithubIcon />
        </a>
        <a title="Twitter" href={props.data.twUrl}>
          <TwitterIcon />
        </a>
      </Container>
      <Banner>
        <p>
          Awesome Icons from Font Awesome take a look at the{' '}
          <a href="https://fontawesome.com/license/free">license for its use</a>
        </p>
      </Banner>
    </Fragment>
  );
};

export default Footer;
