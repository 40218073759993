import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Resume from './components/Resume';
import Contact from './components/Contact';
import ProjectDetail from './components/ProjectDetail';
import Footer from './components/Footer';
import enData from './data/english';
import esData from './data/spanish';

class App extends Component {
  state = {
    activeLang: 'en'
  };

  selectedLanguage = event => {
    this.setState({
      activeLang: event.target.value
    });
  };

  render() {
    const { activeLang } = this.state;
    let data;
    activeLang === 'en' ? (data = enData) : (data = esData);
    const { bio, jobs, education, projects, workshops } = data;
    const resume = { jobs, education, bio, workshops };
    const contactInfo = bio.contact;

    return (
      <Fragment>
        <header>
          <Header
            changeLanguage={this.selectedLanguage}
            activeLang={activeLang}
          />
        </header>
        <main>
          <Switch>
            <Route
              exact
              path="/"
              render={props => <Home {...props} bio={bio} data={projects} />}
            />
            <Route
              path="/projects/:id"
              render={routerProps => (
                <ProjectDetail {...routerProps} data={projects} />
              )}
            />
            <Route
              path="/resume"
              render={props => (
                <Resume {...props} data={resume} activeLang={activeLang} />
              )}
            />
            <Route
              path="/contact"
              render={props => (
                <Contact
                  {...props}
                  data={contactInfo}
                  activeLang={activeLang}
                />
              )}
            />
          </Switch>
        </main>
        <footer>
          <Footer data={contactInfo} />
        </footer>
      </Fragment>
    );
  }
}

export default App;
