const en = {
  bio: {
    name: 'Fabiola Vieyra',
    role: 'JavaScript - Node.js Web Developer',
    bioPic: './images/fab.jpg',
    welcomeMsg:
      "I am a web developer currently living in Madrid, Spain. I am a self-driven and knowledge-hungry person, passionate about JavaScript. I'm interested in creating accessible applications that can be used for everybody. I'm looking to work on interesting and fun projects. Feel free to reach out to me for a project or just to say hello.",

    skills: {
      JavaScript: 'JavaScript, Node.js',
      HTML5: 'Canvas',
      CSS3: 'Flexbox, Grid, Sass',
      Git: 'GitHub',
      'JS Frameworks': 'React'
    },
    languages: {
      English: 'Advance',
      Spanish: 'Native'
    },
    contact: {
      email: 'fabiolacvieyra@gmail.com',
      github: 'https://github.com/Fa-v',
      twitter: '@_Fa_Vi',
      twUrl: 'https://twitter.com/_Fa_Vi',
      location: 'Madrid, Spain',
      connect: "Let's Connect"
    }
  },
  jobs: {
    position: [
      {
        employer: 'Triporate',
        workTitle: 'Full stack developer',
        dates: 'November 2019 - Currentrly',
        workLocation: 'Madrid',
        workDescription:
          'Platform to manage business travel. Work on different layers of the system, from the front end to the back end, mainly integrating third-party APIs as well as developing our own.',
          technologies: 'React - Redux, Node.js, MongoDB, Jest, e2e tests'
        },
        {
          employer: 'DIGIBIS',
          workTitle: 'Front-end developer',
          dates: 'Febrero 2019 - Noviembre de 2019',
          workLocation: 'Madrid',
          workDescription:
            'Server-side Java web apps with an onion architecture. Maintain, refactor and develop software for the three main products that focus on libraries, archives, museums and documentation centres. Worked on improving UX/UI.',
          technologies: 'Technologies: Browser compatibility, Sass, CSS, Accessibility'
        },
        {
          employer: 'FLAD Mentorship',
          workTitle: 'Front-end developer',
          dates: '2019 - 2019',
          workLocation: 'Madrid',
          workDescription:
            'Single-page, responsive web application built with React, Material UI and Firebase that allows entrepreneurs to connect with mentors form the Luso-American community.',
          technologies: 'React, Material UI y Firebase'
        },
    ],
    sectionTitle: 'Work Experience'
  },
  education: {
    schools: [
      {
        school: 'Fictizia',
        degree: 'React - Redux',
        dates: 'Currently',
        location: 'Madrid'
      },
      {
        school: 'Fictizia',
        degree: 'Master Full Stack development with Javascript and Node.js',
        dates: '2018 - 2019',
        location: 'Madrid'
      },
      {
        title: 'Front-end Web Development Nanodegree',
        school: 'Udacity and Women Techmakers Scholar',
        dates: 'September 2017 - February 2018',
        url:
          'https://eu.udacity.com/course/front-end-web-developer-nanodegree--nd001'
      },
      {
        title: 'Front-end Development Certificate',
        school: 'Free Code Camp',
        dates: 'August 2017',
        url: 'https://www.freecodecamp.org/fa-v'
      },
    ],
    sectionTitle: 'Education',
  },
  workshops: {
    data: [
      {
        title: 'Introdution to Git',
        dates: ['December 2018 – Liferay offices.']
      },
      {
        title: 'Introdution to JavaScript',
        dates: ['January 2019 – Liferay offices.', 'March 2019 – Nodegirls, Google for Starups']
      },
      {
        title: 'Introdution to Node.js',
        dates: ['June 2019 – Nodegirls, Google for Starups']
      },
    ],
    sectionTitle: 'Workshops',
  },
  projects: {
    projectInfo: [
      {
        title: 'True Relief',
        dates: 'Currently',
        technologies: 'React, Material UI, Firebase',
        description:
          'Single-page, responsive web application built with React, Material UI and Firebase that allows people to track their pain to better communicate with their physicians and receive feedback about treatments.',
        image: 'trueRelief',
        demo: '',
        repository:
          'Contact me if you need more information about this project.'
      },
      {
        title: 'My web page',
        dates: 'August 2018',
        technologies: 'React, styled components and Netlify',
        description:
          "The first time I build my website in February 2017, I used Harp to build it, Bulma to style it and GitHub pages to host it. This year I decided to rebuild it using React, styled-components and deploy it on Netlify. The main goal was to improve the design and make the content available in Spanish and English. Through this process I've learned about React Router v4, React Helmet and styled-components.",
        image: 'myWebsite',
        demo: 'https://fabiolavieyra.com',
        repository: ''
      },
      {
        title: 'Actress portfolio',
        dates: '2018',
        technologies: 'React, Bulma, Netlify',
        description:
          "I build this site as a portfolio for an actress in 2017. I'd used Harp, Materialize and Surge to publish it. I rebuild it using React, Bulma and Netlify.",
        image: 'lindaWeb',
        demo: 'http://www.lindadelgado.com/',
        repository: 'https://github.com/Fa-v/linda-delgado'
      },
      {
        title: 'Neighborhood Map: wineries in Spain',
        dates: 'February 2018',
        technologies: 'Google maps API, Foursquare API, Knockout.js',
        description:
          'The main goal of this project is to learn about design patterns using a framework, in this case knockout.js, and third-party APIs (Google maps and Foursquare).',
        image: 'map',
        demo: 'https://fa-v.github.io/neighborhood-map/',
        repository: 'https://github.com/Fa-v/neighborhood-map'
      },
      {
        title: 'Arcade Game',
        dates: 'December 2017',
        technologies: 'HTML Canvas, OOP, Github Pages',
        description:
          "This is the third project of the intermediate JavaScript section for Udacity's front-end web development nanodegree. It's a version of the classic frogger arcade-game. The basic requirements for this projects are: The player has to reach the water without colliding into the enemies (bugs). The player can move up, down, left and right without falling out of the screen. The enemies run on the paved block portion of the scene at various speeds. The game resets when the player collides into an enemy. When the player reaches the water the game is won. What I've learned: This project's goal is to learn about object-oriented programming. All functions must be either class functions or class prototype functions. I've learned how to write coherent class prototype functions as well as how the scope and prototype chains works. I've also learned how to use canvas to draw patterns, text and how to create animated elements.",
        image: 'arcadeGame',
        demo: 'https://fa-v.github.io/arcade-game/',
        repository: 'https://github.com/Fa-v/arcade-game'
      },
      {
        title: 'Memory Game',
        dates: 'October 2017 - November 2017',
        technologies: 'JQuery, flexbox, Sass, gulp, Github Pages',
        description:
          "This is the second project of the intermediate JavaScript section for Udacity's front-end web development nanodegree. The main goal was to learn about DOM manipulation using JQuery. It's a card matching game where you have to click on the cards to find every matching pair. You win once you've matched all 8 pairs. Cards are assigned randomly each time you play thanks to the Fisher-Yates shuffle algorithm which makes it possible to efficiently shuffle the cards within the same array and take care of possible repeted elements. The icons are from Font Awesome and I used Flat UI Colors to chose my color palette. I used Sass for styling this project. Thanks to gulp I was able to compile the Sass code into CSS and to watch for changes in all my files.",
        image: 'memoryGame',
        demo: 'https://fa-v.github.io/memory-game/',
        repository: 'https://github.com/Fa-v/memory-game'
      },
      {
        title: 'Simon Game',
        dates: 'July 2017 - August 2017',
        technologies: 'React, CSS Grid Layout, Github Pages',
        description:
          'This Simon Game game is a project for Free Code Camp. It was created with React and bootstrapped with Create React App. I tried the CSS Grid Layout for the first time and I am impressed by how easy is to get started with it',
        image: 'simonGame',
        demo: 'https://fa-v.github.io/simon-game/',
        repository: 'https://github.com/Fa-v/simon-game'
      },
      {
        title: 'Tic-tac-toe',
        dates: 'July 2017',
        technologies: 'React, Github Pages',
        description:
          "Tic-tac-toe is the second of the advance front-end development projects for Free Code Camp. I decided to use React to build this project. Tic-tac-toe could be traced back to ancient Egypt - I didn't know this! I used to play it when I was a kid on paper, even tracing the grid with my finger on the windows on rainy days. It's a pretty simple game where you need to make a line -vertical, horizontal or diagonal- to win.",
        image: 'ticTacToe',
        demo: 'https://fa-v.github.io/react-tic-tac-toe/',
        repository: 'https://github.com/Fa-v/react-tic-tac-toe'
      },
      {
        title: 'Pomodoro Clock',
        dates: '2017',
        technologies: 'React, Github Pages',
        description:
          'The Pomodoro Clock is the second of the advance front-end development projects for Free Code Camp. I decided to use React to build this project. The Pomodoro technique is a management time method that can be used to help you to focus and work on one task for small periods of time, traditionally 25 minutes, followed by a 5-minute break.',
        image: 'pomodoro',
        demo: 'https://fa-v.github.io/react-calculator/',
        repository: 'https://github.com/Fa-v/react-calculator'
      },
      {
        title: 'React Calculator',
        dates: 'June 2017',
        technologies: 'React, Github Pages',
        description:
          "The Calculator is the first of the advance front-end development projects for Free Code Camp. I decided to use React to build this project. I started to learn React while working on this project. I liked that getting started with it was easy because you work directly with JavaScript - React doesn't have many layers of abstraction. I used Create React App to bootstrap the app and published it on gh-pages.",
        image: 'calculator',
        demo: 'https://fa-v.github.io/react-calculator/',
        repository: 'https://github.com/Fa-v/react-calculator'
      },
      {
        title: 'Twitchtv app',
        dates: 'March 2017',
        technologies: 'Angular2, Materialize, Github Pages',
        description:
          "The Twitchtv app is the last of the intermediate front-end development projects for Free Code Camp. This was a challenging project for me. First of all, it was a bit hard to understand how to use the Twitch Api. After I figured out that I had to make three requests to different endpoints to obtain all the data I needed, I decided tho use Angular 2 and Materialize to build this project. I started to learn about observables at the time. I was looking for the best way to process the response of the three calls to the Api. I learned that with observables you can make simultaneous requests and process the data from the streams the observables create. I found that with the 'forkJoin' method you are able to run the calls in parallel and construct an array with combined data of all the requests. To publish the app as a GitHub Page I just needed to run the command 'ng build --prod' which created a dist folder with the minified code that is used in production. After this, I ran 'ng github-pages:deploy' on the command line to deploy the app on GitHub so everybody is able to access it. ",
        image: 'twitchTv',
        demo: 'https://fa-v.github.io/twitchtv_app/',
        repository: 'https://github.com/Fa-v/twitchtv_app'
      },
      {
        title: 'Film Database',
        dates: 'November 2016',
        technologies: 'JQuery, HTML, CSS',
        description:
          'This project was an assignment for the MEAN stack developer course. We had a week to work on it. The main purpose of this exercise was to practice basic CRUD operations locally, where you could add, modify and delete a film. After this, we connected the interface with the Json server to practice ajax calls. Finally we built a mean stack project with Angular 2, a Mongo DB database, in this case I used MongoLab and an Express server.',
        image: 'filmDatabase',
        demo: 'https://codepen.io/Fa-v/pen/VPeKEM',
        repository: 'https://github.com/Fa-v/cine-angular2'
      }
    ],
    sectionTitle: 'Projects'
  },
  places: "Where I've Lived and Worked",
  display: {
    bio: 'displayBio',
    jobs: 'displayJobs',
    projects: 'displayProjects',
    education: 'displayEducation',
    places: 'displayPlaces'
  }
};

export default en;
