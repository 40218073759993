import React from 'react';
import styled from 'styled-components';
import Projects from './Projects';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  margin: 2em;
  padding-top: 112px;
  @media screen and (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
  }
`;

const Section = styled.section`
  margin: 3em 0;
  @media screen and (min-width: 768px) {
    margin: 10em 0;
  }
`;

const StyledLink = styled(Link)`
  font-size: 1em;
  padding: 0.25em 1em;
  line-height: 4em;
  border-radius: 1em;
  text-decoration: none;
  color: ${props => props.theme.secondary.light};
  border: 0.1em solid ${props => props.theme.secondary.light};
  background: ${props => props.theme.primary.light};
  transition: all 0.2s ease;
  &:hover {
    color: ${props => props.theme.primary.light};
    border: 0.1em solid ${props => props.theme.secondary.light};
    background: ${props => props.theme.secondary.light};
  }
`;

const Title = styled.h1`
  font-size: 2em;
  @media screen and (min-width: 768px) {
    font-size: 2.5em;
  }
`;

const Home = props => {
  const { bio, data } = props;

  return (
    <Wrapper>
      <Section>
        <Title>{bio.name}</Title>
        <p>{bio.welcomeMsg}</p>
        <StyledLink to="/contact">Get in touch</StyledLink>
      </Section>
      <Projects {...props} data={data} />
    </Wrapper>
  );
};

export default Home;
