import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 2em;
  @media screen and (min-width: 768px) {
    width: 30%;
    margin: 0 auto;
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 1.5em;
`;

const Title = styled.h2`
  font-size: 2em;
  @media screen and (min-width: 768px) {
    font-size: 2.5em;
  }
`;

const Subtitle = styled.h3`
  font-size: 1.2em;
  color: ${props => props.theme.primary.dark};
  margin: 0 0.5em 0.5em 0;
  @media screen and (min-width: 768px) {
    font-size: 1.5em;
  }
`;

const Link = styled.a`
  color: ${props => props.theme.secondary.light};
  font-weight: 700;
  opacity: 1;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.secondary.dark};
    font-weight: 700;
  }
`;

const Contact = props => {
  const activeLang = props.activeLang;

  return (
    <Wrapper>
      <SectionTitle>
        <Title>{activeLang === "en" ? "Contact" : "Contacto"}</Title>
        <Subtitle>{props.data.connect}</Subtitle>
      </SectionTitle>
      <p>{props.data.email}</p>
      <Link href={props.data.github}>GitHub</Link>
      <Link href={props.data.twitter}>Twitter</Link>
      <p>{props.data.location}</p>
    </Wrapper>
  );
};

export default Contact;
