import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-flow: row wrap;
  }
`;

const Subtitle = styled.h3`
  font-weight: 700;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 2em;
  margin-bottom: 0.5em;
  border: 1px solid ${props => props.theme.secondary.dark};
  color: ${props => props.theme.secondary.light};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.secondary.dark};
  }
  @media screen and (min-width: 768px) {
    flex: auto;
    margin-right: 0.5em;
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 2em;
`;

const Projects = props => {
  const { data } = props;
  let id;

  return (
    <Fragment>
      <SectionTitle>
        <h2>{data.sectionTitle}</h2>
      </SectionTitle>
      <Wrapper>
        {data.projectInfo.map(project => {
          id = project.image;
          return (
            <Fragment key={project.title}>
              <StyledLink to={`/projects/${id}`}>
                <Subtitle>{project.title}</Subtitle>
              </StyledLink>
            </Fragment>
          );
        })}
      </Wrapper>
    </Fragment>
  );
};

export default Projects;
